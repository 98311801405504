import styled from 'styled-components';

const AccordionItemStyles = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px ${props => props.theme.colors.grey5};

  button {
    background: transparent;
    padding: 1rem 2rem 1rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: none;
    cursor: pointer;
    position: relative;
    font-size: 1rem;
    text-align: left;

    p {
      margin: 0;
      color: ${props => props.theme.colors.grey1};
    }

    svg {
      color: ${props => props.theme.colors.claytonBlue};
      width: 1.2rem;
      position: absolute;
      right: 0;
      top: 0.65em;
    }
  }

  .item-content {
    transition: all ${props => props.animationDuration}s ease-in-out;
    overflow: hidden;
    position: relative;
    margin: 0;
    padding: 0;

    .open& {
      padding: 1rem 0 2rem;
    }

    > div {
      height: 100%;
    }
  }
`;

export default AccordionItemStyles;
